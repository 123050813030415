.wrapper {
	width: 100%;
	background-color: var(--base-page-color, #F5F6FA);
	min-height: 100%;
	display: flex;
	flex-direction: column;
	align-items: stretch;
}

.container {
	max-width: 1280px;
	display: flex;
	justify-content: center;
}

.wrapper .logo {
	display: flex;
	align-items: center;
}

/* .wrapper .logo:has(div){
	justify-content: space-between;
} */

.content {
	flex-grow: 1;
	position: relative;
	display: flex;
	justify-content: center;
	text-align: center;
}

.content:has(.work-in-progress) {
	align-items: center;
}

.content .container > div {
	max-width: 768px;
}

.content .container .work-in-progress {
	display: flex;
	flex-direction: column;
	gap: 8px;
	max-width: 522px;
}

.content .container .icon {
	display: flex;
	width: 56px;
	height: 56px;
	justify-content: center;
	align-items: center;
	border-radius: 12px;
	border: 1px solid var(--gray-200, #d2d8e5);
	background: var(--base-white, #fff);
	/* Shadow/xs */
	box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
	margin: auto;
}
.content .container h1 {
	color: var(--gray-900, #30374a);
	text-align: center;
	/* Display sm/Semibold */
	font-family: "Inter";
	font-size: 30px;
	font-style: normal;
	font-weight: 600;
	line-height: 38px; /* 126.667% */
}
.content .container p {
	color: var(--gray-600, #4a5c7f);
	text-align: center;
	/* Text md/Regular */
	font-family: "Inter";
	font-size: 10px;
	font-style: normal;
	font-weight: 400;
	line-height: 24px; /* 150% */
}

.top-label a {
    display: flex;
    padding: 10px 18px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 8px;
    border: 1px solid var(--primary-600, #244fec);
    background: var(--primary-600, #244fec);
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    text-decoration: none;
    color: var(--Base-White, #fff);
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    width: fit-content;
}

.content .container a {
	display: flex;
	gap: 8px;
	/* Shadow/xs */
	/* Text md/Semibold */
	font-family: Inter;
	font-size: 16px;
	font-style: normal;
	font-weight: 600;
	line-height: 24px; /* 150% */
	width: fit-content;
}

.content .container a.product-link {
	box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
	text-decoration: none;
	border-radius: 8px;
	padding: 8px 14px;
	background: var(--primary-600, #244fec);
	color: var(--Base-White, #fff);
	border: 1px solid var(--primary-600, #244fec);
}

.content .container a.login {
	box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
	text-decoration: none;
	gap: 4px;
	padding: 4px 12px;
	border-radius: 16px;
}

.category.documents {
	text-decoration: none;
	color: var(--primary-600, #244fec);
	background-color: var(--primary-50, #EFF4FF);
	border: 1px solid var(--primary-200);
}

.category.documents:hover {
	background-color: var(--primary-200, #EFF4FF);
	color: var(--primary-600, #244fec);
}

.content .container .work-in-progress a {
	margin-top: 24px;
	padding: 10px 18px;
	margin: auto;
	background: var(--primary-600, #244fec);
	color: var(--Base-White, #fff);
	border: 1px solid var(--primary-600, #244fec);
	border-radius: 8px;
}

.content .container .work-in-progress p {
	margin-top: 24px;
	padding: 10px 18px;
	margin: auto;
	font-size: 16px;
}

.category {
 	padding: 10px 14px;
	border-radius: 6px;
	cursor: pointer;
	font-weight: 600;
	color: var(--gray-500, #5E7499);
}

.category:hover {
	box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.06);
	background-color: #fff;
	color: var(--gray-700, #3D4A67);
}

.category:has(input:checked) {
	background-color: #fff;
	color: var(--gray-700, #3D4A67);
}

input:checked + .category:hover {
	box-shadow: 0px 1px 3px 0px rgba(16, 24, 40, 0.1);
}

.wrapper footer {
	background-color: var(--gray-100, #ebedf3);
	padding: 16px;
	display: flex;
	align-items: center;

	color: var(--Gray-800, #354057);
	font-family: Inter;
	font-size: 12px;
	font-style: normal;
	font-weight: 400;
	line-height: 18px; /* 225% */
}

@media screen and (max-width: 998px) {
	.content .logo {
		padding-top: 16px;
	}
}
