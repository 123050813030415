@import url("https://fonts.googleapis.com/css?family=Inter:regular,bold,italic&subset=latin,latin-ext");
@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
	--base-page-color: #f5f6fa;
	--gray-100: #ebedf3;
	--gray-200: #d2d8e5;
	--gray-600: #4a5c7f;
	--gray-900: #30374a;
	--base-white: #fff;
	--primary-50: #EFF4FF;
	--primary-200: #BFD5FE;
	--primary-600: #244fec;
}

html,
body,
#root {
	margin: 0;
	font-family: "Inter", sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;

	width: 100%;
	height: 100%;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
		monospace;
}

select.select-category  {
    -moz-appearance:none; /* Firefox */
    -webkit-appearance:none; /* Safari and Chrome */
    appearance:none;
}

select::-ms-expand {
	display: none;
  }

.custom-select {
	position: relative;
	width: fit-content;
	cursor: pointer;
}

#signin .custom-select {
	margin-top: 40px;
	margin-inline: auto;
}

.custom-select-opener {
	padding: 12px 14px;
	border: 1px solid #abb8ce;
	box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
	border-radius: 8px;
	display: flex;
	align-items: center;
	gap: 20px;
	background-color: white;
	cursor: pointer;
}

.custom-select-opener > div {
	display: flex;
	align-items: center;
	gap: 8px;
	line-height: 1;
}

.custom-select-options-container {
	display: none;
	overflow: hidden;
	position: absolute;
	top: calc(100% + 8px);
	left: 0;
	right: 0;
	width: 100%;
	max-height: 140px;
	background-color: white;
	border: 1px solid #abb8ce;
	box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.08),
		0px 4px 6px -2px rgba(16, 24, 40, 0.03);
	border-radius: 8px;
}

.custom-select-options-container.opened {
	display: flex;
}

.custom-select-options-container > div {
	width: 100%;
	flex-direction: column;
	display: flex;
	gap: 6px;
	overflow: auto;
	padding: 6px;
}

.custom-select-option {
	display: flex;
	align-items: center;
	gap: 8px;
	border: none;
	background-color: white;
	border-radius: 8px;
	width: 100%;
	padding: 4px;
	cursor: pointer;
}

.custom-select-option:hover {
	background-color: #f5f6fa;
}

.custom-select:has(.custom-select-options-container.opened)
	.custom-select-opener,
.custom-select-opener:hover {
	box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px #dbe7fe !important;
}

@media screen and (max-width: 998px) {
	#signin .custom-select {
		margin-top: 40px;
		margin-bottom: 50px;
	}

	.custom-select label {
		display: none;
	}

	.custom-select-options-container {
		max-height: 90px;
		min-width: fit-content;
		/*left: unset;*/
	}

	#signup .custom-select-options-container {
		max-height: 120px;
	}
}